import './google.css';
import './App.css';
import './wp.css'
import './grunion.css'
import './image-gallery.css'
import './my.css'

import Gallery from "./components/Gallery";

function App() {
    return (
        <div className="content">
            <div lang="fr" dir="ltr" id="sitewrapper" data-default-theme-name="Austin" className="N6bHlf jY7uzd TnsHTb">
                <div className="BLytye">
                    <h1 className="hero__title hY9UDb ">
                        <span className="hero__title-content CoIOBe nbOMh" itemProp="name" data-field="headline">
                            Gîte le Tiguidou
                        </span>
                    </h1>
                    <div className="notification "><span className="notification-content" itemProp="description"
                                                         data-field="announcement">
                        Gîte à Chanverrie</span>
                    </div>
                    <div className="hero__strapline teQaN " data-field="hours-header">
                        4 La Trechonnière, Chanverrie
                    </div>
                    <div>
                    </div>
                </div>
                <div className="PTfVdf b0t70b" id="summary" data-panel="summary-panel">
                    <div className="dlg3Sd">
                        <hr className="WUdCTb"/>
                        <h2 className=" Igsabe"><span className="lead__title-content" data-field="summary-header">À 10 km du Puy du Fou gîte pour 4 personnes</span>
                        </h2>
                        <div className="lead__summary-content" data-field="summary-body" jslog="61736; track:impression"
                             jsname="U0N86e"
                             itemProp="disambiguatingDescription">Nous sommes heureux de vous accueillir dans notre Gîte
                            de 60m<sup>2</sup> sur 2
                            étages,&nbsp; classé meublé de tourisme 3 étoiles par l'Office de Tourisme de Vendée.&nbsp;
                            <div><br/>
                                <div>Au rez-de-chaussée :
                                    <div>
                                        <div>
                                            <div>
                                                <div dir="ltr">1 pièce de vie de 20m<sup>2</sup> avec cuisine équipée et
                                                    séjour.<br/>1 chambre de
                                                    16m<sup>2</sup> avec lit double (salle de bain indépendante)<br/>Une
                                                    grande baie vous donne
                                                    accès à une terrasse privée.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div dir="ltr"><br/></div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div dir="ltr"><span>À l’étage :<br/>1 chambre de 16m<sup>2</sup> avec 2 lits simples (salle de bain indépendante)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div dir="ltr"><span><br/></span></div>
                                            <div><span><div>Le logement</div></span></div>
                                            <div>
                                                <div dir="ltr">Wifi<br/>Lave-vaisselle<br/>Micro-ondes<br/>Cuisine
                                                    équipée<br/>Canapé lit au
                                                    salon<br/>Entrée privée<br/>Parking<br/>Service de buanderie
                                                    disponible<br/>Équipement
                                                    de bébé disponible (chaise haute, lit parapluie)<br/>Literie et
                                                    linge de
                                                    toilette-maison inclus<br/>Terrasse privée avec table, chaises de
                                                    jardin et BBQ.
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div><span><div><div><br/></div></div></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="y72IR">
                        <hr className="WUdCTb"/>
                        <h2 className="Igsabe">Galerie</h2>
                    </div>
                    <Gallery/>
                </div>
                <div id="details" data-panel="business-details-panel" className="IJMoHc b0t70b">
                    <hr className="WUdCTb"/>
                    <h2 className="Igsabe">Nous contacter</h2>
                    <div className="IQ1KEb">
                        <a href="https://www.google.com/maps/place/G%C3%AEte+le+Tiguidou/data=!3m1!4b1!4m2!3m1!1s0x4806691a0a884e9b:0x56a7c9f8edfe1e6b"
                           target="_blank" rel="noreferrer" itemProp="hasMap" data-tracking-element-type="7">
                            <div className="QMbmRe" style={{backgroundImage: "url('images/staticmap.jpeg')"}}
                                 title="Carte indiquant l'emplacement de l'établissement">
                            </div>
                        </a>
                    </div>
                    <div className="fGZQDc">
                        <div className="kCmrbf">
                            <h3 className="Gou21b">Coordonnées</h3>
                            <div data-field="phone">
                                <a className="PDvGL q8cvFf" href="tel:+33-6-61-58-00-48" data-tracking-element-type="3"
                                   itemProp="telephone" dir="ltr">Appeler maintenant</a>
                                <ul className="R7Di0e">
                                    <li dir="ltr">06 61 58 00 48</li>
                                </ul>
                            </div>
                        </div>
                        <div className="kCmrbf">
                            <h3 className="Gou21b">Adresse</h3>
                            <div data-field="address">
                                <a className="PDvGL q8cvFf" rel="noreferrer"
                                   href="https://www.google.com/maps/dir//G%C3%AEte+le+Tiguidou/data=!4m8!4m7!1m0!1m5!1m1!1s0x4806691a0a884e9b:0x56a7c9f8edfe1e6b!2m2!1d-0.9919842999999999!2d46.976708599999995"
                                   target="_blank" data-tracking-element-type="6">Itinéraire</a>
                                <address className="qhkvMe " itemProp="address">
                                    <div>4 La Trechonnière</div>
                                    <div>85130 Chanverrie</div>
                                    <div>France</div>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Contact/>*/}
        </div>
    );
}

export default App;

export const images = [
  {
    original: "images/gallery/sejour-2.jpg",
    thumbnail: "images/gallery/thumbnail/sejour-2.jpg",
  },
  {
    original: "images/gallery/terrasse-1.jpg",
    thumbnail: "images/gallery/thumbnail/terrasse-1.jpg",
  },
  {
    original: "images/gallery/parking.jpg",
    thumbnail: "images/gallery/thumbnail/parking.jpg",
  },
  {
    original: "images/gallery/sejour-1.jpg",
    thumbnail: "images/gallery/thumbnail/sejour-1.jpg",
  },
  {
    original: "images/gallery/ch1.jpg",
    thumbnail: "images/gallery/thumbnail/ch1.jpg",
  },
  {
    original: "images/gallery/sdb1.jpg",
    thumbnail: "images/gallery/thumbnail/sdb1.jpg",
  },
  {
    original: "images/gallery/ch2.jpg",
    thumbnail: "images/gallery/thumbnail/ch2.jpg",
  },
  {
    original: "images/gallery/sdb2.jpg",
    thumbnail: "images/gallery/thumbnail/sdb2.jpg",
  },
  {
    original: "images/gallery/logo.jpg",
    thumbnail: "images/gallery/thumbnail/logo.jpg",
  }
];
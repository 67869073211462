import ImageGallery from "react-image-gallery";
import { images } from "../data/gallery-image";

function Gallery() {
  return (
    <div className="app">
      <div className="image-gallery-wrapper">
        <ImageGallery items={images}
            showFullscreenButton={false}
            autoPlay={false}/>
      </div>
    </div>
  );
}

export default Gallery;